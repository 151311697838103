import Header from "./components/header/Header";
import Banner from "./components/banner/Banner";
import Dashboard from "./assets/images/dashboard.png";
import Features from "./components/features/Features";
import VideoPlayer from "./components/video-player/VideoPlayer";
import BookDemo from "./components/book-demo/BookDemo";
import Footer from "./components/footer/Footer";
import { ToastContainer, Slide } from "react-toastify";
import { useRef } from "react";
import Benefits from "./components/benefits/Benefits";

const App = () => {
  const bookDemo = useRef(null);
  const features = useRef(null);
  const benefits = useRef(null);
  const video = useRef(null);
  const contact = useRef(null);

  return (
    <>
      <Header bookDemoRef={bookDemo} featuresRef={features} videoRef={video} benefitsRef={benefits} contactRef={contact} />
      <main className="container mx-auto px-10">
        <Banner />
        <img
          src={Dashboard}
          alt="dashboard"
          className="pb-12 md:pb-24"
          loading="lazy"
        />
        <Features featuresRef={features} />
      </main>
      <VideoPlayer videoRef={video} />
      <main className="container mx-auto px-10">
        <Benefits benefitsRef={benefits} />
      </main>
      <BookDemo bookDemoRef={bookDemo} />
      <Footer contactRef={contact} />
      <ToastContainer
        position="bottom-center"
        hideProgressBar
        newestOnTop
        theme="colored"
        transition={Slide}
      />
    </>
  );
};

export default App;
