import FacilitateTransaction from "../assets/images/facilitate-transactions.png";
import LiveInventory from "../assets/images/live-inventory.png";
import SeamlessDeal from "../assets/images/seamless-deal.png";

const BENEFITS = [
  {
    image: FacilitateTransaction,
    tag: "Automated Payment Transactions",
    title: "Facilitate Transactions with Speed",
    description:
      "Reduce inefficient processes caused by repetitive manual activities, such as back-and-forth processing of checks. 99 Keys ensures deposits go through 24/7 and provides live, transparent updates on payment status and timing.",
  },
  {
    image: LiveInventory,
    tag: "Seamless Channel Management",
    title: "Share Live Inventory Across Different Channels",
    description:
      "Manage all your units in one platform, keep your availability, pricing, rates, and inventory updated in real time, whether your client books on your official platforms or through your sales representatives.",
  },
  {
    image: SeamlessDeal,
    tag: "Automated Payment Transactions",
    title: "Unlock Seamless Deal automation from Start to Finish.",
    description:
      "Automate agreement creation, streamline stakeholder notifications, expedite closings, and manage commissions, deposits, and more with 99 Keys. Plus, the platform is integrated with Identify Verification to reduce risk by simplifying KYC and AML requirements",
  },
];

export default BENEFITS;
