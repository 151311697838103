import { FC } from "react";
import { Modal } from "react-responsive-modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import WishListModalInterface from "../../interfaces/wish-list-modal-interface";
import sendEmail from "../../utils/sendEmail";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  company: yup.string().required("Company / Organization is required"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .matches(
      /^\+?[1-9]\d{1,14}$/,
      "Phone Number must be in a valid international format (e.g., +18884628462)"
    )
    .max(15),
});

const WishListModal: FC<WishListModalInterface> = ({
  open,
  setOpen,
  email,
  resetEmail,
}) => {
  const onCloseModal = () => setOpen(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: object) => {
    sendEmail({ ...data, email }, true);
    resetEmail();
    reset();
    onCloseModal();
  };

  return (
    <Modal
      open={open}
      onClose={onCloseModal}
      center
      classNames={{ modal: "rounded" }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bg-white rounded-xl px-8 md:px-14 py-6 md:py-12 max-w-[544px]"
      >
        <p className="text-primary font-semibold text-xl mb-7">
          Complete Your Sign-Up Below for Exclusive Early Access to the
          Platform.
        </p>
        <p className="text-xs md:text-base mb-7">
          Be the first to know about our launch updates by filling in your
          details and joining our exclusive waitlist.
        </p>
        <div className="flex flex-wrap gap-4 w-full">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 flex-grow">
            <div className="w-full">
              <input
                type="text"
                placeholder="First Name"
                {...register("firstName")}
                className={`bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full border hover:border-gray-500 focus:border-primary ${
                  errors.firstName ? "border-red-500" : ""
                }`}
              />
              {errors.firstName && (
                <p className="text-red-500 text-xs">
                  {errors.firstName.message}
                </p>
              )}
            </div>
            <div className="w-full">
              <input
                type="text"
                placeholder="Last Name"
                {...register("lastName")}
                className={`bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full border hover:border-gray-500 focus:border-primary ${
                  errors.lastName ? "border-red-500" : ""
                }`}
              />
              {errors.lastName && (
                <p className="text-red-500 text-xs">
                  {errors.lastName.message}
                </p>
              )}
            </div>
          </div>
          <div className="w-full">
            <input
              type="text"
              placeholder="Phone Number"
              {...register("phoneNumber")}
              className={`bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full border hover:border-gray-500 focus:border-primary ${
                errors.phoneNumber ? "border-red-500" : ""
              }`}
            />
            {errors.phoneNumber && (
              <p className="text-red-500 text-xs">
                {errors.phoneNumber.message}
              </p>
            )}
          </div>
          <div className="w-full">
            <input
              type="text"
              placeholder="Email"
              value={email}
              readOnly
              className="bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full"
            />
          </div>
          <div className="w-full">
            <input
              type="text"
              placeholder="Company / Organization"
              {...register("company")}
              className={`bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full border hover:border-gray-500 focus:border-primary ${
                errors.company ? "border-red-500" : ""
              }`}
            />
            {errors.company && (
              <p className="text-red-500 text-xs">{errors.company.message}</p>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="px-3 py-2 md:px-6 md:py-4 bg-primary-gradient text-sm font-semibold text-white my-6 rounded-full w-full"
        >
          Submit
        </button>
        <p className="text-xs md:text-base">
          By submitting this information, I acknowledge I have read and agree to
          the Terms & Use, including its Privacy section.
        </p>
      </form>
    </Modal>
  );
};

export default WishListModal;
