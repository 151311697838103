import RealTimeInventory from "../assets/images/real-time.png";
import UnifiedCommunicationHub from "../assets/images/unified-communication-hub.png";
import AutomatedTransactions from "../assets/images/automated-transactions.png";
import VirtualSalesOffice from "../assets/images/virtual-sales-office.png";
import SeamlessChannelManagement from "../assets/images/channel-management.png";
import CRM from "../assets/images/crm.png";

const FEATURES = [
  {
    image: RealTimeInventory,
    title: "Real-Time Inventory Management",
    description:
      "Track the status of units, floor plans, and reservation information in real-time.",
  },
  {
    image: AutomatedTransactions,
    title: "Automated Transactions",
    description:
      "Streamline processes such as APS, payment processing, and legal documentation.",
  },
  {
    image: UnifiedCommunicationHub,
    title: "Unified Communication Hub",
    description:
      "Facilitate seamless communication among stakeholders, including developers, agents, and clients.",
  },
  {
    image: VirtualSalesOffice,
    title: "Virtual Sales Office",
    description:
      "Create immersive experiences showcasing condo units to clients through 3D walk tours.",
  },
  {
    image: SeamlessChannelManagement,
    title: "Seamless Channel Management",
    description:
      "Manage all your sales channels and ensure consistent project information and pricing across all platforms.",
  },
  {
    image: CRM,
    title: "CRM",
    description:
      "Efficiently manage leads and track sales progress for all team members.",
  },
];

export default FEATURES;
