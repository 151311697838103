import { MutableRefObject } from "react";
import BENEFITS from "../../constants/benefits";
import useDetectScroll, { Axis } from "@smakss/react-scroll-direction";

import FacilitateTransaction from "../../assets/images/facilitate-transactions.png";
import LiveInventory from "../../assets/images/live-inventory.png";
import SeamlessDeal from "../../assets/images/seamless-deal.png";

const Benefits = ({ benefitsRef }: { benefitsRef: MutableRefObject<any> }) => {
  const { scrollPosition } = useDetectScroll({ axis: Axis.Y });

  const displayPhoto = () => {
    if (scrollPosition.top > 4800 && scrollPosition.top < 5200) {
      return LiveInventory;
    }
    if (scrollPosition.top > 5200) {
      return SeamlessDeal;
    }
    return FacilitateTransaction;
  };

  return (
    <>
      <div ref={benefitsRef} className="h-32 lg:h-96" />
      <div>
        <div className="hidden lg:grid grid-cols-2 mb-64">
          <div>
            {BENEFITS.map((benefit, i) => (
              <div
                className={`max-w-lg ${
                  i === 0
                    ? "mb-96"
                    : i === BENEFITS.length - 1
                    ? "mt-96"
                    : "my-96"
                }`}
                key={i}
              >
                <div className="bg-primary w-fit px-3 py-1 rounded-full text-white font-semibold mb-4">
                  {benefit.tag}
                </div>
                <div className="text-4xl leading-[45px] font-semibold mb-5">
                  {benefit.title}
                </div>
                <div className="text-lg leading-[30px]">
                  {benefit.description}
                </div>
              </div>
            ))}
          </div>
          <div>
            <img
              src={displayPhoto()}
              alt="banner"
              className="h-96 sticky top-80 w-[546px]"
            />
          </div>
        </div>
        <div className="lg:hidden mb-32">
          {BENEFITS.map((benefit, i) => (
            <div className="mb-24">
              <img
                src={benefit.image}
                alt="banner"
                className="h-52 w-[300px] mb-12"
              />
              <div key={i}>
                <div className="bg-primary w-fit text-xs px-3 py-1 rounded-full text-white font-semibold mb-2">
                  {benefit.tag}
                </div>
                <div className="text-2xl leading-8 font-semibold mb-3">
                  {benefit.title}
                </div>
                <div className="text-sm md:text-base leading-5">
                  {benefit.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Benefits;
