import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MutableRefObject } from "react";
import sendEmail from "../../utils/sendEmail";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .matches(
      /^\+?[1-9]\d{1,14}$/,
      "Phone Number must be in a valid international format (e.g., +18884628462)"
    )
    .max(15),
  email: yup.string().required("Email is required").email("Email is invalid"),
  company: yup.string().required("Company / Organization is required"),
});

const BookDemo = ({ bookDemoRef }: { bookDemoRef: MutableRefObject<any> }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: object) => {
    sendEmail(data);
    reset();
  };

  return (
    <div ref={bookDemoRef} className="py-[128px] bg-book-me-gradient px-10">
      <div className="container mx-auto flex flex-col lg:grid lg:grid-cols-2 justify-center items-center">
        <div className="space-y-5 mb-10 lg:mb-0">
          <p className="text-4xl md:text-5xl text-white font-bold">
            Book a Demo with Us Today
          </p>
          <p className="text-white text-sm md:text-base lg:text-lg">
            Request your beta demo today to discover the key to optimized
            operations tomorrow.
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white rounded-xl px-8 md:px-14 py-6 md:py-12 max-w-[544px]"
        >
          <p className="text-primary font-semibold text-xl mb-7">
            Fill in the Information Details
          </p>
          <div className="flex flex-wrap gap-4 w-full">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 flex-grow">
              <div className="w-full">
                <input
                  type="text"
                  placeholder="First Name"
                  {...register("firstName")}
                  className={`bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full border hover:border-gray-500 focus:border-primary ${
                    errors.firstName ? "border-red-500" : ""
                  }`}
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs">
                    {errors.firstName.message}
                  </p>
                )}
              </div>
              <div className="w-full">
                <input
                  type="text"
                  placeholder="Last Name"
                  {...register("lastName")}
                  className={`bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full border hover:border-gray-500 focus:border-primary ${
                    errors.lastName ? "border-red-500" : ""
                  }`}
                />
                {errors.lastName && (
                  <p className="text-red-500 text-xs">
                    {errors.lastName.message}
                  </p>
                )}
              </div>
            </div>
            <div className="w-full">
              <input
                type="text"
                placeholder="Phone Number"
                {...register("phoneNumber")}
                className={`bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full border hover:border-gray-500 focus:border-primary ${
                  errors.phoneNumber ? "border-red-500" : ""
                }`}
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-xs">
                  {errors.phoneNumber.message}
                </p>
              )}
            </div>
            <div className="w-full">
              <input
                type="email"
                placeholder="Email"
                {...register("email")}
                className={`bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full border hover:border-gray-500 focus:border-primary ${
                  errors.email ? "border-red-500" : ""
                }`}
              />
              {errors.email && (
                <p className="text-red-500 text-xs">{errors.email.message}</p>
              )}
            </div>
            <div className="w-full">
              <input
                type="text"
                placeholder="Company / Organization"
                {...register("company")}
                className={`bg-[#F3F3F3] text-xs sm:text-sm md:text-base px-3 py-2 md:px-6 md:py-4 outline-none rounded-md w-full border hover:border-gray-500 focus:border-primary ${
                  errors.company ? "border-red-500" : ""
                }`}
              />
              {errors.company && (
                <p className="text-red-500 text-xs">{errors.company.message}</p>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="px-3 py-2 md:px-6 md:py-4 bg-primary-gradient text-sm font-semibold text-white my-6 rounded-full w-full"
          >
            Submit
          </button>
          <p className="text-xs md:text-base">
            We'll reach out to you within the next business hour to help you
            with your journey. By submitting this information, I acknowledge I
            have read and agree to the Terms & Use, including its Privacy
            section.
          </p>
        </form>
      </div>
    </div>
  );
};

export default BookDemo;
