import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import WishListModal from "../modals/WishListModal";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email format"),
});

const Banner: FC = () => {
  const [openWishListModal, setOpenWishListModal] = useState(false);
  const [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: any) => {
    setEmail(data.email);
    setOpenWishListModal(true);
  };

  return (
    <div className="h-[450px] md:h-[509px] flex flex-col items-center justify-center gap-9">
      <p className="font-semibold text-primary text-3xl sm:text-4xl md:text-5xl max-w-2xl text-center leading-10 md:leading-[65px]">
        One Platform to Manage Your New Homes Inventory
      </p>
      <p className="text-center text-sm md:text-base">
        Helping builders manage their live inventory and selling process
        efficiently
      </p>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-grey rounded-full flex"
        >
          <input
            type="email"
            autoComplete="off"
            {...register("email")}
            className={`outline-none bg-grey w-48 md:w-96 py-2 px-3 md:py-3 md:px-6 rounded-full text-sm md:text-base ${
              errors.email ? "border-red-500" : ""
            }`}
            placeholder="Enter your e-mail"
          />
          <button
            type="submit"
            className="bg-primary text-sm md:text-base shadow-primary text-white py-2 px-3 md:py-4 md:px-6 rounded-full font-semibold text-nowrap"
          >
            Join the Waitlist
          </button>
        </form>
        {errors.email && (
          <p className="text-red-500 text-xs ms-5 mt-2">{errors.email.message}</p>
        )}
      </div>
      <WishListModal open={openWishListModal} setOpen={setOpenWishListModal} email={email} resetEmail={reset}
      />
    </div>
  );
};

export default Banner;
