import useDetectScroll, { Axis } from "@smakss/react-scroll-direction";
import { RxHamburgerMenu } from "react-icons/rx";

import logo from "../../assets/images/logo.png";
import play from "../../assets/images/play.png";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { MutableRefObject } from "react";

const Header = ({
  bookDemoRef,
  featuresRef,
  benefitsRef,
  videoRef,
  contactRef,
}: {
  bookDemoRef: MutableRefObject<any>;
  featuresRef: MutableRefObject<any>;
  benefitsRef: MutableRefObject<any>;
  videoRef: MutableRefObject<any>;
  contactRef: MutableRefObject<any>;
}) => {
  const { scrollPosition } = useDetectScroll({ axis: Axis.Y });

  const hamburgerMenu = (
    <div className="border border-gray-500 rounded p-1 cursor-pointer">
      <RxHamburgerMenu size={20} />
    </div>
  );

  const handleBookDemoClick = () => {
    bookDemoRef.current.scrollIntoView({behavior: 'smooth'});
  };

  const handleFeaturesClick = () => {
    featuresRef.current.scrollIntoView({behavior: 'smooth'});
  };

  const handleBenefitsClick = () => {
    benefitsRef.current.scrollIntoView({behavior: 'smooth'});
  };

  const handleVideoClick = () => {
    videoRef.current.scrollIntoView({behavior: 'smooth'});
  };

  const handleContactClick = () => {
    contactRef.current.scrollIntoView({behavior: 'smooth'});
  };

  return (
    <header
      className={`${
        scrollPosition.top > 94 && "shadow-lg"
      } sticky top-0 z-10 bg-white text-sm lg:text-base px-10`}
    >
      <div className="container mx-auto flex justify-between items-center py-7">
        <div>
          <img src={logo} className="w-[100px] lg:w-[118px]" alt="logo" />
        </div>
        <div className="hidden lg:flex gap-7">
          <p className="cursor-pointer" onClick={handleFeaturesClick}>
            Features
          </p>
          <p className="cursor-pointer" onClick={handleBenefitsClick}>
            Benefits
          </p>
          <p className="cursor-pointer" onClick={handleContactClick}>
            Contact Us
          </p>
        </div>
        <div className="flex items-center gap-6 lg:gap-8">
          <div
            className="hidden bg-grey px-3 py-1.5 lg:px-6 lg:py-3 rounded-full md:flex items-center gap-2 cursor-pointer"
            onClick={handleVideoClick}
          >
            <p>Watch Video</p>
            <img src={play} className="h-6 w-6 lg:h-8 lg:w-8" alt="play" />
          </div>
          <div
            className="shadow-primary-gradient px-3 py-1.5 lg:px-6 lg:py-3 rounded-full text-white bg-primary-gradient cursor-pointer"
            onClick={handleBookDemoClick}
          >
            Book a Demo
          </div>
          <div className="lg:hidden">
            <Menu menuButton={hamburgerMenu} transition direction="left" arrow>
              <MenuItem onClick={handleFeaturesClick}>Features</MenuItem>
              <MenuItem onClick={handleBenefitsClick}>Benefits</MenuItem>
              <MenuItem onClick={handleContactClick}>Contact Us</MenuItem>
              <div className="md:hidden">
                <MenuItem>
                  <div
                    className="bg-grey px-3 py-1.5 rounded w-full flex items-center gap-2"
                    onClick={handleVideoClick}
                  >
                    <p>Watch Video</p>
                    <img
                      src={play}
                      className="h-6 w-6 lg:h-8 lg:w-8"
                      alt="play"
                    />
                  </div>
                </MenuItem>
              </div>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
