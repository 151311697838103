import { FC } from "react";
import FeatureItemInterface from "../../interfaces/feature-item-interface";

const FeatureItem: FC<FeatureItemInterface> = (props) => {
  const { image, title, description } = props;

  return (
    <div className="py-3 md:py-[4.5rem] hover:scale-110 transition-all duration-700">
      <img src={image} alt="service" className="mb-9" />
      <p className="text-base md:text-lg font-bold mb-3">{title}</p>
      <p className="text-sm md:text-base">{description}</p>
    </div>
  );
};

export default FeatureItem;
