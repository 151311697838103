import fbSvg from "../../assets/svgs/facebook.svg";
import linkedinSvg from "../../assets/svgs/linkedin.svg";
import instaSvg from "../../assets/svgs/instagram.svg";
import logo from "../../assets/images/logo.png";
import { MutableRefObject } from "react";

const Footer = ({ contactRef }: { contactRef: MutableRefObject<any> }) => {
  return (
    <div
      ref={contactRef}
      className="container mx-auto py-32 flex flex-col md:flex-row gap-8 md:gap-0 justify-between px-10"
    >
      <div>
        <img src={logo} alt="logo" className="h-20 md-6 md:mb-14" />
        <p className="text-sm md:text-base">
          99 Keys, Inc 70 Jutland Rd Toronto, ON M8Z 2G6
        </p>
      </div>
      <div>
        <p className="text-xl font-semibold mb-4 md:mb-7">Follow Us</p>
        <div className="flex items-center gap-6">
          <a
            href="https://www.facebook.com/99Keys.ca"
            target="_blank"
            rel="noreferrer"
          >
            <img src={fbSvg} alt="fb-icon" />
          </a>
          <a
            href="https://ca.linkedin.com/company/99-keys"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedinSvg} alt="linkedin-icon" />
          </a>
          <a
            href="https://www.instagram.com/99keys.ca"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instaSvg} alt="insta-icon" />
          </a>
        </div>
      </div>
      <div>
        <p className="text-xl font-semibold mb-4 md:mb-7">Contact Us</p>
        <p>info@99keys.ca</p>
      </div>
    </div>
  );
};

export default Footer;
