import { MutableRefObject } from "react";
import { BigPlayButton, Player } from "video-react";

const videoUrl =
  "https://res.cloudinary.com/diqoy2zj7/video/upload/v1717516045/99_Keys-Trimmed_ognycg.mp4";

const VideoPlayer = ({ videoRef }: { videoRef: MutableRefObject<any> }) => {
  return (
    <div ref={videoRef}>
      <Player playsInline src={videoUrl}>
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};

export default VideoPlayer;
