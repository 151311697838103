import { toast } from "react-toastify";
import _ from "lodash";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";

const sendEmail = async (data: object, isWishList = false) => {
  const body = toSnakeCase(data);
  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;
  let templateId = process.env.REACT_APP_BOOK_DEMO_TEMPLATE_ID;

  if (isWishList) templateId = process.env.REACT_APP_WISHLIST_TEMPLATE_ID;

  if (templateId && serviceId)
    try {
      await toast.promise(
        emailjs.send(serviceId, templateId, body, {
          publicKey,
        }),
        {
          pending: "Pending sending email",
          success: "Email has been successfully sent",
          error: "Error sending email",
        }
      );
    } catch (err) {
      if (err instanceof EmailJSResponseStatus) {
        console.log(err);
        return;
      }

      console.log("ERROR", err);
    }
};

const toSnakeCase = (obj: object) => {
  return _.mapKeys(obj, (value, key) => _.snakeCase(key));
};

export default sendEmail;
