import { MutableRefObject } from "react";
import FeatureItem from "./FeatureItem";
import FEATURES from "../../constants/features";

const Features = ({ featuresRef }: { featuresRef: MutableRefObject<any> }) => {
  return (
    <div ref={featuresRef} className="py-12 md:py-24">
      <div className="flex flex-col gap-5 py-7">
        <p className="text-3xl sm:text-4xl md:text-5xl font-semibold max-w-3xl">
          Streamline Your Operations Within a Centralized Workspace
        </p>
        <p className="text-sm md:text-base leading-[30px]">
          Improve productivity, collaboration, and overall business performance.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14">
        {FEATURES.map((feature, i) => (
          <FeatureItem key={i} {...feature} />
        ))}
      </div>
    </div>
  );
};

export default Features;
